import * as React from 'react';
import BaseComponent from '@/components/BaseComponent';
import { Router, Route, Switch, withRouter, Redirect } from 'react-router-dom';
import NoMatch from '@/components/NoMatch';
import PageLoading from '@/components/BaseComponent/PageLoading';


/**
 *
 * 渲染路由
 * @class RouterUI
 * @extends {BaseComponent<IProps>}
 */
class RouterUI extends BaseComponent {
  /**
   * 生成router
   * @param {*} routers
   * @param {*} container
   * @param {*} recur 是否递归
   */
  renderRouter = (routers = []) =>
    routers.map(router => {
      let { path, exact, unAuth} = router;
      if (unAuth) { }
      return (
        <Route
          key={path}
          path={path}
          exact={exact ? true : false}
          render={() => this.renderPage(router)}
        />
      );
    });

    renderPage = (router) => {
        const {component, path, loadingFallback, mobileComponent} = router;
        let IsMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        let Page = IsMobile ? mobileComponent : component ;
        return (
            <React.Suspense fallback={loadingFallback || <PageLoading/>} key={path}>
                <Page/>
            </React.Suspense>
        );
    };

  render() {
    const { routers } = this.props;
    return (
      <Switch>
          <Redirect exact from="/" to="/index" />
        {this.renderRouter(routers)}
        <Route component={NoMatch} />
      </Switch>

    );
  }
}

export default withRouter(RouterUI);
