import React from 'react';
import ReactDOM from 'react-dom';
import App from '@/App';
import { BrowserRouter } from "react-router-dom";
import 'antd/dist/antd.less';
import '@/assets/css/index.less';

import 'antd-mobile/es/global'
import ErrorBoundary from './components/ErrorBoundary';
import Modal from 'react-modal';

import VConsole from 'vconsole';

let IsMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)

if (IsMobile) {
    import ('./wap.less');
}else {
    import ('./index.less');

}

if(ENV !== 'prod' && location.host.indexOf('localhost') == -1){
    const vConsole = new VConsole();
}
try {
  const rootElement = document.getElementById('root');

  Modal.setAppElement(rootElement);

  const renderApp = () => {
    ReactDOM.render(
      <React.StrictMode>
          <ErrorBoundary>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
          </ErrorBoundary>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  };
  renderApp();
} catch (e) {
  console.log('e');
}
