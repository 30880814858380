import React, {useState, useReducer, useEffect} from 'react';
import {useHistory, useLocation, useParams} from "react-router-dom";
import routerConfig from './router/index';
import RouterUI from '@/router/RouterUI';
import PageLoading from '@/components/BaseComponent/PageLoading';
import AppContext from './common/AppContext';

import commonSetting from '@/config/commonSetting'

import { getUserAgent } from '@/util/handleUrlParams'

function reducer(prevState, action) {
    // reducer will read the value from the latest render
    const {count: prevCount} = prevState;
    const {type, payload} = action;
    if (type === "tokens") {
        return {...prevState, tokens: payload};
    } else if (type === "wxInfo") {
        return {...prevState, wxInfo: payload};
    } else if (type === "userInfo") {
        return {...prevState, userInfo: payload};
    } else if (type === "redirect") {
        return {...prevState, redirect: payload};
    } else {
        throw new Error();
    }
}
const App = (props) => {

    const [loading, setLoading] = useState(false)
    const [state, dispatch] = useReducer(reducer, {
        accountType: commonSetting.ACCOUNT_TYPE,
        productType: commonSetting.PRODUCT_TYPE
    });
    let history = useHistory();
    const isWechatEnv = getUserAgent().get("isWechat")
    console.log('history', history)



    useEffect(() => {


    }, [])
    return (
        <>
            <AppContext.Provider value={{...state, dispatch: dispatch}}>
                {
                    loading ? <PageLoading></PageLoading>
                        : <RouterUI routers={routerConfig.routes}/>
                }
            </AppContext.Provider>
        </>
    );
}

export default App;
