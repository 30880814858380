import React from 'react';



export default class ErrorBoundary extends React.Component {
  static defaultProps = {
    type: 'global',
    message: '页面崩溃啦！',
    isDialog: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(_error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log('ErrorBoundary', error, errorInfo);
    this.setState({
      error,
    });
  }


  renderErrorComponent() {
    // const { message } = this.props;
    // const { error } = this.state;
    // const Com = this.getErrorComponent(type);

    return <div>页面报错</div>;
    //return this.getErrorComponent()
    // return <Com message={props.message} />;
  }

  render() {
    if (this.state.hasError) {
      return this.renderErrorComponent();
    }
    return this.props.children;
  }
}

